import './App.css';
import React from 'react';
import Routing from './routing';

function App() {
  return (
    <Routing/>
  );
}

export default App;
