import styled from "styled-components";

export const Badge = styled.div`
width: 70px;
height: 20px;
background-color: blue;
margin-right: 12px;
text-align: center;
border-radius: 5px;
color: white
`;